import { BlendFunction, BloomEffect, BrightnessContrastEffect, ChromaticAberrationEffect, ColorAverageEffect, ColorDepthEffect, DepthEffect, EffectComposer as EffectComposer$1, RenderPass, NormalPass, DepthDownsamplingPass, EffectPass, DepthOfFieldEffect, DotScreenEffect, GlitchEffect, GlitchMode, GodRaysEffect, GridEffect, HueSaturationEffect, NoiseEffect, OutlineEffect, PixelationEffect, ScanlineEffect, SelectiveBloomEffect, SepiaEffect, SSAOEffect, SMAAEffect, TextureEffect, ToneMappingEffect, VignetteEffect, ShockWaveEffect, LUT3DEffect, TiltShiftEffect as TiltShiftEffect$1, Effect, EffectAttribute } from 'postprocessing';
import { jsx } from 'react/jsx-runtime';
import React, { forwardRef, useMemo, createContext, useEffect, useRef, useLayoutEffect, useImperativeHandle, useContext, useState } from 'react';
import * as THREE from 'three';
import { HalfFloatType, Vector3, TextureLoader, sRGBEncoding, RepeatWrapping, Uniform } from 'three';
import { extend, useThree, useFrame, useInstanceHandle, useLoader } from '@react-three/fiber';
import { isWebGL2Available } from 'three-stdlib';
import { SSREffect } from 'screen-space-reflections';

const resolveRef = (ref) => typeof ref === 'object' && ref != null && 'current' in ref ? ref.current : ref;
let i = 0;
const components = new WeakMap();
const wrapEffect = (effect, defaults) => 
/* @__PURE__ */ React.forwardRef(function Effect({ blendFunction = defaults === null || defaults === void 0 ? void 0 : defaults.blendFunction, opacity = defaults === null || defaults === void 0 ? void 0 : defaults.opacity, ...props }, ref) {
    let Component = components.get(effect);
    if (!Component) {
        const key = `@react-three/postprocessing/${effect.name}-${i++}`;
        extend({ [key]: effect });
        components.set(effect, (Component = key));
    }
    const camera = useThree((state) => state.camera);
    const args = React.useMemo(() => { var _a, _b; return [...((_a = defaults === null || defaults === void 0 ? void 0 : defaults.args) !== null && _a !== void 0 ? _a : []), ...((_b = props.args) !== null && _b !== void 0 ? _b : [{ ...defaults, ...props }])]; }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(props)]);
    return (jsx(Component, { camera: camera, "blendMode-blendFunction": blendFunction, "blendMode-opacity": opacity, ...props, ref: ref, args: args }));
});
const useVector2 = (props, key) => {
    const value = props[key];
    return React.useMemo(() => {
        if (typeof value === 'number')
            return new THREE.Vector2(value, value);
        else if (value)
            return new THREE.Vector2(...value);
        else
            return new THREE.Vector2();
    }, [value]);
};

const Bloom = wrapEffect(BloomEffect, { blendFunction: BlendFunction.ADD });

const BrightnessContrast = wrapEffect(BrightnessContrastEffect);

const ChromaticAberration = forwardRef(function ChromaticAberration(props, ref) {
    const offset = useVector2(props, 'offset');
    const effect = useMemo(() => new ChromaticAberrationEffect({ ...props, offset }), [offset, props]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const ColorAverage = forwardRef(function ColorAverage({ blendFunction = BlendFunction.NORMAL }, ref) {
    /** Because ColorAverage blendFunction is not an object but a number, we have to define a custom prop "blendFunction" */
    const effect = useMemo(() => new ColorAverageEffect(blendFunction), [blendFunction]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const ColorDepth = wrapEffect(ColorDepthEffect);

const Depth = wrapEffect(DepthEffect);

const EffectComposerContext = createContext(null);
const EffectComposer = React.memo(forwardRef(({ children, camera: _camera, scene: _scene, resolutionScale, enabled = true, renderPriority = 1, autoClear = true, depthBuffer, disableNormalPass, stencilBuffer, multisampling = 8, frameBufferType = HalfFloatType, }, ref) => {
    const { gl, scene: defaultScene, camera: defaultCamera, size } = useThree();
    const scene = _scene || defaultScene;
    const camera = _camera || defaultCamera;
    const [composer, normalPass, downSamplingPass] = useMemo(() => {
        const webGL2Available = isWebGL2Available();
        // Initialize composer
        const effectComposer = new EffectComposer$1(gl, {
            depthBuffer,
            stencilBuffer,
            multisampling: multisampling > 0 && webGL2Available ? multisampling : 0,
            frameBufferType,
        });
        // Add render pass
        effectComposer.addPass(new RenderPass(scene, camera));
        // Create normal pass
        let downSamplingPass = null;
        let normalPass = null;
        if (!disableNormalPass) {
            normalPass = new NormalPass(scene, camera);
            normalPass.enabled = false;
            effectComposer.addPass(normalPass);
            if (resolutionScale !== undefined && webGL2Available) {
                downSamplingPass = new DepthDownsamplingPass({ normalBuffer: normalPass.texture, resolutionScale });
                downSamplingPass.enabled = false;
                effectComposer.addPass(downSamplingPass);
            }
        }
        return [effectComposer, normalPass, downSamplingPass];
    }, [
        camera,
        gl,
        depthBuffer,
        stencilBuffer,
        multisampling,
        frameBufferType,
        scene,
        disableNormalPass,
        resolutionScale,
    ]);
    useEffect(() => composer === null || composer === void 0 ? void 0 : composer.setSize(size.width, size.height), [composer, size]);
    useFrame((_, delta) => {
        if (enabled) {
            gl.autoClear = autoClear;
            composer.render(delta);
        }
    }, enabled ? renderPriority : 0);
    const group = useRef(null);
    const instance = useInstanceHandle(group);
    useLayoutEffect(() => {
        let effectPass;
        if (group.current && instance.current && composer) {
            effectPass = new EffectPass(camera, ...instance.current.objects);
            effectPass.renderToScreen = true;
            composer.addPass(effectPass);
            if (normalPass)
                normalPass.enabled = true;
            if (downSamplingPass)
                downSamplingPass.enabled = true;
        }
        return () => {
            if (effectPass)
                composer === null || composer === void 0 ? void 0 : composer.removePass(effectPass);
            if (normalPass)
                normalPass.enabled = false;
            if (downSamplingPass)
                downSamplingPass.enabled = false;
        };
    }, [composer, children, camera, normalPass, downSamplingPass, instance]);
    // Memoize state, otherwise it would trigger all consumers on every render
    const state = useMemo(() => ({ composer, normalPass, downSamplingPass, resolutionScale, camera, scene }), [composer, normalPass, downSamplingPass, resolutionScale, camera, scene]);
    // Expose the composer
    useImperativeHandle(ref, () => composer, [composer]);
    return (jsx(EffectComposerContext.Provider, { value: state, children: jsx("group", { ref: group, children: children }) }));
}));

const DepthOfField = forwardRef(function DepthOfField({ target, depthTexture, ...props }, ref) {
    const invalidate = useThree((state) => state.invalidate);
    const { camera } = useContext(EffectComposerContext);
    const effect = useMemo(() => new DepthOfFieldEffect(camera, props), [camera, props]);
    useLayoutEffect(() => {
        if (target && typeof target !== 'number') {
            const vec = target instanceof Vector3
                ? new Vector3().set(target.x, target.y, target.z)
                : new Vector3().set(target[0], target[1], target[2]);
            effect.target = vec;
        }
        if (depthTexture)
            effect.setDepthTexture(depthTexture.texture, depthTexture.packing);
        invalidate();
    }, [target, depthTexture, effect]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const DotScreen = wrapEffect(DotScreenEffect);

const Glitch = forwardRef(function Glitch({ active = true, ...props }, ref) {
    const invalidate = useThree((state) => state.invalidate);
    const delay = useVector2(props, 'delay');
    const duration = useVector2(props, 'duration');
    const strength = useVector2(props, 'strength');
    const effect = useMemo(() => new GlitchEffect({ ...props, delay, duration, strength }), [delay, duration, props, strength]);
    useLayoutEffect(() => {
        effect.mode = active ? props.mode || GlitchMode.SPORADIC : GlitchMode.DISABLED;
        invalidate();
    }, [active, effect, invalidate, props.mode]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const GodRays = forwardRef(function GodRays(props, ref) {
    const { camera } = useContext(EffectComposerContext);
    const effect = useMemo(() => new GodRaysEffect(camera, props.sun, props), [camera, props]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const Grid = forwardRef(function Grid({ size, ...props }, ref) {
    const invalidate = useThree((state) => state.invalidate);
    const effect = useMemo(() => new GridEffect(props), [props]);
    useLayoutEffect(() => {
        if (size)
            effect.setSize(size.width, size.height);
        invalidate();
    }, [effect, size]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const HueSaturation = wrapEffect(HueSaturationEffect);

const Noise = wrapEffect(NoiseEffect, { blendFunction: BlendFunction.COLOR_DODGE });

const selectionContext = createContext(null);
function Selection({ children, enabled = true }) {
    const [selected, select] = useState([]);
    const value = useMemo(() => ({ selected, select, enabled }), [selected, select, enabled]);
    return jsx(selectionContext.Provider, { value: value, children: children });
}
function Select({ enabled = false, children, ...props }) {
    const group = useRef(null);
    const api = useContext(selectionContext);
    useEffect(() => {
        if (api && enabled) {
            let changed = false;
            const current = [];
            group.current.traverse((o) => {
                o.type === 'Mesh' && current.push(o);
                if (api.selected.indexOf(o) === -1)
                    changed = true;
            });
            if (changed) {
                api.select((state) => [...state, ...current]);
                return () => {
                    api.select((state) => state.filter((selected) => !current.includes(selected)));
                };
            }
        }
    }, [enabled, children, api]);
    return (jsx("group", { ref: group, ...props, children: children }));
}

const Outline = forwardRef(function Outline({ selection = [], selectionLayer = 10, blendFunction, patternTexture, edgeStrength, pulseSpeed, visibleEdgeColor, hiddenEdgeColor, width, height, kernelSize, blur, xRay, ...props }, forwardRef) {
    const invalidate = useThree((state) => state.invalidate);
    const { scene, camera } = useContext(EffectComposerContext);
    const effect = useMemo(() => new OutlineEffect(scene, camera, {
        blendFunction,
        patternTexture,
        edgeStrength,
        pulseSpeed,
        visibleEdgeColor,
        hiddenEdgeColor,
        width,
        height,
        kernelSize,
        blur,
        xRay,
        ...props,
    }), [
        blendFunction,
        blur,
        camera,
        edgeStrength,
        height,
        hiddenEdgeColor,
        kernelSize,
        patternTexture,
        props,
        pulseSpeed,
        scene,
        visibleEdgeColor,
        width,
        xRay,
    ]);
    const api = useContext(selectionContext);
    useEffect(() => {
        // Do not allow array selection if declarative selection is active
        // TODO: array selection should probably be deprecated altogether
        if (!api && selection) {
            effect.selection.set(Array.isArray(selection) ? selection.map(resolveRef) : [resolveRef(selection)]);
            invalidate();
            return () => {
                effect.selection.clear();
                invalidate();
            };
        }
    }, [effect, selection, api, invalidate]);
    useEffect(() => {
        effect.selectionLayer = selectionLayer;
        invalidate();
    }, [effect, invalidate, selectionLayer]);
    useRef();
    useEffect(() => {
        var _a;
        if (api && api.enabled) {
            if ((_a = api.selected) === null || _a === void 0 ? void 0 : _a.length) {
                effect.selection.set(api.selected);
                invalidate();
                return () => {
                    effect.selection.clear();
                    invalidate();
                };
            }
        }
    }, [api, effect.selection, invalidate]);
    return jsx("primitive", { ref: forwardRef, object: effect });
});

const Pixelation = forwardRef(function Pixelation({ granularity = 5 }, ref) {
    /** Because GlitchEffect granularity is not an object but a number, we have to define a custom prop "granularity" */
    const effect = useMemo(() => new PixelationEffect(granularity), [granularity]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const Scanline = wrapEffect(ScanlineEffect, { blendFunction: BlendFunction.OVERLAY });

const addLight = (light, effect) => light.layers.enable(effect.selection.layer);
const removeLight = (light, effect) => light.layers.disable(effect.selection.layer);
const SelectiveBloom = forwardRef(function SelectiveBloom({ selection = [], selectionLayer = 10, lights = [], luminanceThreshold, luminanceSmoothing, intensity, width, height, kernelSize, mipmapBlur, ...props }, forwardRef) {
    if (lights.length === 0) {
        console.warn('SelectiveBloom requires lights to work.');
    }
    const invalidate = useThree((state) => state.invalidate);
    const { scene, camera } = useContext(EffectComposerContext);
    const effect = useMemo(() => new SelectiveBloomEffect(scene, camera, {
        blendFunction: BlendFunction.ADD,
        luminanceThreshold,
        luminanceSmoothing,
        intensity,
        width,
        height,
        kernelSize,
        mipmapBlur,
        ...props,
    }), [scene, camera, luminanceThreshold, luminanceSmoothing, intensity, width, height, kernelSize, mipmapBlur, props]);
    const api = useContext(selectionContext);
    useEffect(() => {
        // Do not allow array selection if declarative selection is active
        // TODO: array selection should probably be deprecated altogether
        if (!api && selection) {
            effect.selection.set(Array.isArray(selection) ? selection.map(resolveRef) : [resolveRef(selection)]);
            invalidate();
            return () => {
                effect.selection.clear();
                invalidate();
            };
        }
    }, [effect, selection, api, invalidate]);
    useEffect(() => {
        effect.selection.layer = selectionLayer;
        invalidate();
    }, [effect, invalidate, selectionLayer]);
    useEffect(() => {
        if (lights && lights.length > 0) {
            lights.forEach((light) => addLight(resolveRef(light), effect));
            invalidate();
            return () => {
                lights.forEach((light) => removeLight(resolveRef(light), effect));
                invalidate();
            };
        }
    }, [effect, invalidate, lights, selectionLayer]);
    useRef();
    useEffect(() => {
        var _a;
        if (api && api.enabled) {
            if ((_a = api.selected) === null || _a === void 0 ? void 0 : _a.length) {
                effect.selection.set(api.selected);
                invalidate();
                return () => {
                    effect.selection.clear();
                    invalidate();
                };
            }
        }
    }, [api, effect.selection, invalidate]);
    return jsx("primitive", { ref: forwardRef, object: effect, dispose: null });
});

const Sepia = wrapEffect(SepiaEffect);

const SSAO = forwardRef(function SSAO(props, ref) {
    const { camera, normalPass, downSamplingPass, resolutionScale } = useContext(EffectComposerContext);
    const effect = useMemo(() => {
        if (normalPass === null && downSamplingPass === null) {
            console.error('Please enable the NormalPass in the EffectComposer in order to use SSAO.');
            return {};
        }
        return new SSAOEffect(camera, normalPass && !downSamplingPass ? normalPass.texture : null, {
            blendFunction: BlendFunction.MULTIPLY,
            samples: 30,
            rings: 4,
            distanceThreshold: 1.0,
            distanceFalloff: 0.0,
            rangeThreshold: 0.5,
            rangeFalloff: 0.1,
            luminanceInfluence: 0.9,
            radius: 20,
            bias: 0.5,
            intensity: 1.0,
            color: undefined,
            // @ts-ignore
            normalDepthBuffer: downSamplingPass ? downSamplingPass.texture : null,
            resolutionScale: resolutionScale !== null && resolutionScale !== void 0 ? resolutionScale : 1,
            depthAwareUpsampling: true,
            ...props,
        });
    }, [camera, normalPass, props]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const SMAA = wrapEffect(SMAAEffect);

const Texture = forwardRef(function Texture({ textureSrc, texture, ...props }, ref) {
    const t = useLoader(TextureLoader, textureSrc);
    useLayoutEffect(() => {
        t.encoding = sRGBEncoding;
        t.wrapS = t.wrapT = RepeatWrapping;
    }, [t]);
    const effect = useMemo(() => new TextureEffect({ ...props, texture: t || texture }), [props, t, texture]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const ToneMapping = wrapEffect(ToneMappingEffect);

const Vignette = wrapEffect(VignetteEffect);

const ShockWave = wrapEffect(ShockWaveEffect);

const LUT = forwardRef(function LUT({ lut, tetrahedralInterpolation, ...props }, ref) {
    const effect = useMemo(() => new LUT3DEffect(lut, props), [lut, props]);
    const invalidate = useThree((state) => state.invalidate);
    useLayoutEffect(() => {
        if (tetrahedralInterpolation)
            effect.tetrahedralInterpolation = tetrahedralInterpolation;
        if (lut)
            effect.lut = lut;
        invalidate();
    }, [effect, invalidate, lut, tetrahedralInterpolation]);
    return jsx("primitive", { ref: ref, object: effect, dispose: null });
});

const TiltShift = wrapEffect(TiltShiftEffect$1, { blendFunction: BlendFunction.ADD });

const TiltShiftShader = {
    fragmentShader: `

    // original shader by Evan Wallace

    #define MAX_ITERATIONS 100

    uniform float blur;
    uniform float taper;
    uniform vec2 start;
    uniform vec2 end;
    uniform vec2 direction;
    uniform int samples;

    float random(vec3 scale, float seed) {
        /* use the fragment position for a different seed per-pixel */
        return fract(sin(dot(gl_FragCoord.xyz + seed, scale)) * 43758.5453 + seed);
    }

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
        vec4 color = vec4(0.0);
        float total = 0.0;
        vec2 startPixel = vec2(start.x * resolution.x, start.y * resolution.y);
        vec2 endPixel = vec2(end.x * resolution.x, end.y * resolution.y);
        float f_samples = float(samples);
        float half_samples = f_samples / 2.0;

        // use screen diagonal to normalize blur radii
        float maxScreenDistance = distance(vec2(0.0), resolution); // diagonal distance
        float gradientRadius = taper * (maxScreenDistance);
        float blurRadius = blur * (maxScreenDistance / 16.0);

        /* randomize the lookup values to hide the fixed number of samples */
        float offset = random(vec3(12.9898, 78.233, 151.7182), 0.0);
        vec2 normal = normalize(vec2(startPixel.y - endPixel.y, endPixel.x - startPixel.x));
        float radius = smoothstep(0.0, 1.0, abs(dot(uv * resolution - startPixel, normal)) / gradientRadius) * blurRadius;

        #pragma unroll_loop_start
        for (int i = 0; i <= MAX_ITERATIONS; i++) {
            if (i >= samples) { break; } // return early if over sample count
            float f_i = float(i);
            float s_i = -half_samples + f_i;
            float percent = (s_i + offset - 0.5) / half_samples;
            float weight = 1.0 - abs(percent);
            vec4 sample_i = texture2D(inputBuffer, uv + normalize(direction) / resolution * percent * radius);
            /* switch to pre-multiplied alpha to correctly blur transparent images */
            sample_i.rgb *= sample_i.a;
            color += sample_i * weight;
            total += weight;
        }
        #pragma unroll_loop_end

        outputColor = color / total;

        /* switch back from pre-multiplied alpha */
        outputColor.rgb /= outputColor.a + 0.00001;
    }
    `,
};
class TiltShiftEffect extends Effect {
    constructor({ blendFunction = BlendFunction.NORMAL, blur = 0.15, // [0, 1], can go beyond 1 for extra
    taper = 0.5, // [0, 1], can go beyond 1 for extra
    start = [0.5, 0.0], // [0,1] percentage x,y of screenspace
    end = [0.5, 1.0], // [0,1] percentage x,y of screenspace
    samples = 10.0, // number of blur samples
    direction = [1, 1], // direction of blur
     } = {}) {
        super('TiltShiftEffect', TiltShiftShader.fragmentShader, {
            blendFunction,
            attributes: EffectAttribute.CONVOLUTION,
            uniforms: new Map([
                ['blur', new Uniform(blur)],
                ['taper', new Uniform(taper)],
                ['start', new Uniform(start)],
                ['end', new Uniform(end)],
                ['samples', new Uniform(samples)],
                ['direction', new Uniform(direction)],
            ]),
        });
    }
}
const TiltShift2 = wrapEffect(TiltShiftEffect, { blendFunction: BlendFunction.NORMAL });

const SSR = forwardRef(function SSR({ ENABLE_BLUR = true, USE_MRT = true, ...props }, ref) {
    const { invalidate } = useThree();
    const { scene, camera } = useContext(EffectComposerContext);
    const effect = useMemo(() => new SSREffect(scene, camera, { ENABLE_BLUR, USE_MRT, ...props }), [scene, camera, ENABLE_BLUR, USE_MRT, props]);
    const api = useContext(selectionContext);
    useEffect(() => {
        var _a;
        if (api && api.enabled) {
            if ((_a = api.selected) === null || _a === void 0 ? void 0 : _a.length) {
                effect.selection.set(api.selected);
                invalidate();
                return () => {
                    effect.selection.clear();
                    invalidate();
                };
            }
        }
    }, [api]);
    return jsx("primitive", { ref: ref, object: effect, ...props });
});

export { Bloom, BrightnessContrast, ChromaticAberration, ColorAverage, ColorDepth, Depth, DepthOfField, DotScreen, EffectComposer, EffectComposerContext, Glitch, GodRays, Grid, HueSaturation, LUT, Noise, Outline, Pixelation, SMAA, SSAO, SSR, Scanline, Select, Selection, SelectiveBloom, Sepia, ShockWave, Texture, TiltShift, TiltShift2, TiltShiftEffect, ToneMapping, Vignette, resolveRef, selectionContext, useVector2, wrapEffect };
